import React from "react";
import { Link } from "react-router-dom";

interface INavLinkProps {
  text: string;
  route: string;
  active: boolean;
  disabled: boolean | null | undefined;
}

export default class NavLink extends React.Component<INavLinkProps> {
  static defaultProps = {
    disabled: false
  };

  render() {
    return (
      <li className={this.itemClasses}>
        <Link className={this.linkClasses} to={this.props.route}>
          {this.props.text}
        </Link>
      </li>
    );
  }

  private get itemClasses() {
    var c = "nav-item";
    if (this.props.active) {
      c += " active";
    }
    return c;
  }

  private get linkClasses() {
    var c = "nav-link";
    if (this.props.disabled) {
      c += " disabled";
    }
    return c;
  }
}
