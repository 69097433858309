import rng from "./Rng";

class TreasureGenerator {
  generate = (params: TreasureParameters): TreasureResult => {
    const result = this.emptyResult();
    if (params.farFromHome)
      result.bonus.push(
        "Langt væk hjemmefra: Mindst 1 ration, brugbar af enhver med samme smag"
      );
    if (params.magical)
      result.bonus.push("Magisk: En mærkelig genstand, muligvis magisk");
    if (params.divine)
      result.bonus.push("Guddommelig: Et tegn på een eller flere guddomme");
    if (params.planar)
      result.bonus.push("Anden dimension: Noget der ikke er fra denne værden");
    return this._generate(params, result);
  };

  private _generate = (
    params: TreasureParameters,
    result: TreasureResult
  ): TreasureResult => {
    let score = params.diceFn();
    if (params.hoarder) score = Math.max(score, params.diceFn());
    if (params.lordOverTheOthers) score += rng.int(1, 4);
    if (params.ancientAndNoteworthy) score += rng.int(1, 4);

    if (score < 1) score = 1;
    else if (score >= 18) score = 18;

    const item = itemTypes[score - 1];
    result.items.push(item);
    if (15 <= score && score <= 17) return this._generate(params, result);
    return result;
  };

  emptyResult = (): TreasureResult => ({
    items: [],
    bonus: []
  });
}

export type TreasureParameters = {
  diceFn: () => number;
  hoarder: boolean | null | undefined;
  farFromHome: boolean | null | undefined;
  magical: boolean | null | undefined;
  divine: boolean | null | undefined;
  planar: boolean | null | undefined;
  lordOverTheOthers: boolean | null | undefined;
  ancientAndNoteworthy: boolean | null | undefined;
};

export type TreasureResult = {
  items: string[];
  bonus: string[];
};

const itemTypes = [
  "1: Få mønter (ca. 2d8)",
  "2: En genstand der er nyttig ifht. den nuværende situation",
  "3: Nogle mønter (ca. 4d10)",
  "4: En lille genstand (ædelsten, kunst) af betydelig værdi. Vejer 0, lige så meget værd som 2d10×10 guld",
  "5: En lille magisk nipsting",
  "6: Brugbar information (i form af spor, noter, etc.)",
  "7: En pose guld (ca. 1d4×100)",
  "8: En meget værdifuld lille ting (ædelsten, kunst) med en værdi på 2d6×100 guld. 0 vægt",
  "9: En kiste med mønter eller andre værdigenstande. 1 vægt, men 3d6×100 guld værd",
  "10: En magisk genstand eller magisk effekt",
  "11: Mange pengeposer med en samlet værdi af omkring 2d4×100 guld",
  "12: Et tegn på tilhørsforhold (en krone, et banner e.l.) med en værdi på mindst 3d4×100 guld",
  "13: En stor kunstgenstand med en værdi på 4d4×100 guld. 1 vægt",
  "14: En unik genstand med en værdi på mindst 5d4×100 guld",
  "15: Al nødvendig information til at lære en ny besværgelse",
  "16: En portal eller hemmelig sti (eller anvisninger hertil)",
  "17: Noget der relaterer til een af karaktererne",
  "18: En kæmpe skat: 1d10×1000 guld og 1d10×10 ædelstene hver med en værdi på 2d6×100"
];

const instance = new TreasureGenerator();
export default instance;
