import rng from "./Rng";

class NpcGenerator {
  private titleCase = (text: string) =>
    text.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

  name = (syllables = -1): string => {
    if (syllables <= 0) syllables = rng.int(2, 3);
    let name = "";
    for (let i = 0; i < syllables; i++) {
      name += rng.pick(nameSyllables);
    }
    return this.titleCase(name);
  };

  instinct = (): string => rng.pick(instincts);
  knack = (): string => rng.pick(knacks);
}

const nameGenerator = new NpcGenerator();
export default nameGenerator;

const nameSyllables = [
  "a",
  "ael",
  "ah",
  "al",
  "ar",
  "as",
  "at",
  "bar",
  "bin",
  "bog",
  "bul",
  "cat",
  "char",
  "cir",
  "dael",
  "dan",
  "der",
  "dor",
  "e",
  "er",
  "et",
  "far",
  "fel",
  "fem",
  "fin",
  "fir",
  "fred",
  "gosh",
  "hen",
  "hor",
  "i",
  "ic",
  "ik",
  "in",
  "jor",
  "kar",
  "lar",
  "lon",
  "lot",
  "lyn",
  "man",
  "met",
  "mor",
  "nes",
  "o",
  "ot",
  "oz",
  "pid",
  "pil",
  "rak",
  "ri",
  "rin",
  "ruk",
  "run",
  "ryl",
  "ryr",
  "rys",
  "saur",
  "sek",
  "sten",
  "sus",
  "tak",
  "te",
  "tel",
  "ter",
  "tin",
  "tir",
  "tor",
  "tuk",
  "u",
  "wyn",
  "zak",
  "zar",
  "zik"
];

const instincts = [
  "hævne",
  "sprede det gode ord",
  "genforene med en elsket",
  "tjene penge",
  "gøre det godt igen",
  "udforske et mystisk sted",
  "afdække en skjult sandhed",
  "finde en mistet genstand",
  "dræbe en hadet fjende",
  "erobre et fjernt land",
  "helbrede en sygdom",
  "skabe et mesterværk",
  "overleve bare een dag mere",
  "fortjene kærlighed",
  "bevise en pointe",
  "blive klogere, hurtigere og stærkere",
  "helbrede et gammelt sår eller skade",
  "dræbe en ondskab for bestandigt",
  "skjule sig fra en skammelig eller pinlig kendsgerning",
  "evangelisere",
  "sprede lidelse",
  "bevise en værdi",
  "stige i rang",
  "blive rost",
  "finde sandheden",
  "tjene godt på et væddemål",
  "komme ud af en forpligtelse",
  "overbevise nogen om at gøre sit beskidte arbejde",
  "stjæle noget værdifuldt",
  "overvinde en dårlig vane",
  "begå en grusomhed",
  "vinde berømmelse",
  "tilegne sig magt",
  "redde nogen fra en monstrositet",
  "undervise",
  "slå sig ned",
  "score bare een gevinst mere",
  "opretholde loven",
  "opdage",
  "fortære",
  "genoprette familiens navn",
  "leve det stille liv",
  "hjælpe andre",
  "(af)sone",
  "bevise sit værd",
  "få ære",
  "udvide sit land",
  "få en titel",
  "trække sig fra samfundet",
  "flygte",
  "feste",
  "vende hjem",
  "tjene",
  "genvinde hvad der blev taget",
  "gøre hvad der må gøres",
  "være en mester",
  "undgå opmærksomhed",
  "hjælpe et familiemedlem",
  "mestre en færdighed",
  "rejse",
  "overkomme en ulempe",
  "spille spillet",
  "skabe et dynasti",
  "forbedre riget",
  "gå på pension",
  "genfinde et tabt minde",
  "kæmpe",
  "blive et mareridt for kriminelle",
  "opfostre drager",
  "leve op til forventninger",
  "blive en anden",
  "gøre det umulige",
  "blive husket i sang",
  "blive glemt",
  "finde ægte kærlighed",
  "miste besindelsen eller blive sindssyg",
  "forkæle",
  "få det bedste ud af situationen",
  "finde den eneste ene",
  "ødelægge en artefakt",
  "vise dem! Vise dem alle",
  "skabe en uendelig sommer",
  "flyve",
  "finde manden med seks fingre",
  "vække de gamle sovende",
  "underholde",
  "følge en ordre",
  "dø en strålende død",
  "være forsigtig",
  "udvise venlighed",
  "undgå at fucke alting op",
  "afdække fortiden",
  "gå hvor intet menneske har gået før",
  "udføre gode gerninger",
  "blive et bæst",
  "spilde blod",
  "leve for evigt",
  "jage det farligste bytte",
  "hade",
  "flygte"
];

const knacks = [
  "kriminelle forbindelser",
  "lejesvende",
  "evner med et specifikt våben",
  "selvlært magi",
  "omfattende lokalviden",
  "adelt blod",
  "en enestående genstand",
  "en særlig skæbne",
  "et unikt perspektiv",
  "skjult viden",
  "magisk opmærksomhed",
  "ualmindelig opdragelse",
  "politisk indflydelse",
  "en forbindelse til et monster",
  "en hemmelighed",
  "ægte kærlighed",
  "et uskyldigt hjerte",
  "en plan til den perfekte forbrydelse",
  "en envejs-billet til paradis",
  "et mystisk metal",
  "penge, penge, penge",
  "guddommelig velsignelse",
  "immunitet fra loven",
  "profeti",
  "hemmelige kampkunst-teknikker",
  "en magtfuld ring",
  "en tiltrængt pose kartofler",
  "et hjerte",
  "en befæstet position",
  "lovgivning",
  "tunger",
  "et skælnende øje",
  "udholdenhed",
  "et sikkert sted",
  "syner og visioner",
  "et smukt sind",
  "en klar stemme",
  "et smukt udseende",
  "en fængende melodi",
  "opfindelse",
  "bagning",
  "brygning",
  "smeltning",
  "træbearbejdning",
  "skrivning",
  "immunitet overfor ild",
  "madlavning",
  "historefortælling",
  "rottefængeri",
  "løgne",
  "fuldstændig ubemærkelsesværdighed",
  "svimlende sexappeal",
  "udefinerbar coolness",
  "en evne med knuder",
  "hjul af pudset stål",
  "et magisk tæppe",
  "endeløse idéer",
  "vedholdenhed",
  "et lager af mad",
  "en skjult sti",
  "fromhed",
  "resistens mod sygdom",
  "et bibliotek",
  "en tunge af sølv",
  "en blodlinje",
  "en medfødt besværgelse",
  "balance",
  "sjæle",
  "hurtighed",
  "en sans for rigtigt og forkert",
  "vished",
  "et øje for detaljer",
  "heltemodig selvopopfrelse",
  "retningssans",
  "en stor idé",
  "en skjult indgang til byen",
  "kærligheden til en magtfuld person",
  "ubestridelig loyalitet",
  "exotisk frugt",
  "gift",
  "fotografisk hukommelse",
  "fuglenes sprog",
  "en nøgle til en vigtig dør",
  "metalbearbejdning",
  "mystiske velgørende",
  "nerver af stål",
  "at bluffe",
  "en trænet ulv",
  "en længe mistet søskende, genvundet",
  "en pil med dit navn på",
  "et sandt navn",
  "held",
  "opmærksomhed fra overnaturlige kræfter",
  "venlighed",
  "mærkelige tatoveringer",
  "et majestætisk skæg",
  "en bog i et mærkeligt sprog",
  "overvældende magt eller styrke",
  "vrangforestillinger om egne evner",
  "vinden i ryggen og lethed til fods"
];
