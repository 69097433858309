import React from "react";
import { MonsterHelperState } from "./MonsterHelper";
import { ResultCardProps } from "./ResultCard";

interface Props {
  mhs: MonsterHelperState;
  rcp: ResultCardProps;
}

interface State {
  active: "json" | "markdown";
}

export default class MonsterExport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: "markdown"
    };
  }

  render = () => (
    <div className="row">
      <div className="col">
        <button
          className="btn btn-info"
          onClick={() => navigator.clipboard.writeText(this.getMarkdown())}
        >
          Copy markdown
        </button>
      </div>
      <div className="col">
        <button
          className="btn btn-info"
          onClick={() => navigator.clipboard.writeText(this.getJson())}
        >
          Copy json
        </button>
      </div>
    </div>
  );

  getText = (): string => {
    switch (this.state.active) {
      case "json":
        return this.getJson();
      case "markdown":
        return this.getMarkdown();
      default:
        return "";
    }
  };

  getJson = (): string => {
    return JSON.stringify(this.props.mhs, null, 2);
  };

  getMarkdown = (): string => {
    return `
      # ${this.props.rcp.name}

      ## Description
      ${this.props.rcp.description}

      _${[...this.props.rcp.monsterTags].join(", ")}_

      ## Attack
      ${this.props.rcp.weapon} (${this.props.rcp.damage}); ${
      this.props.rcp.hp
    } HP; ${this.props.rcp.armor} Armor  
      ${[...this.props.rcp.weaponTags].join(", ")}

      ## Instinct
      ${this.props.rcp.instinct}

      ## Moves

      ${this.props.rcp.moves.map(m => `- ${m}`).join("\n")}

      ## Info

      ### Interesting
      
      ${this.props.rcp.interesting}

      ### Useful

      ${this.props.rcp.useful}

    `.replace(/^ +/gm, "");
  };
}
