import range from "./Range";
import rng from "./Rng";

const regex = /^\s*(?<amount>\d*)d(?<sides>\d+)(?<modifier>[+-]\d+)?\s*$/;
export const roll = (d: string): number => {
  const match = d.match(regex);
  if (!match) throw new Error(`'${d}' is not a valid dice string!`);

  const groups = match.groups;
  if (!groups) throw new Error("No groups in match!");

  const amount = +groups.amount || 1;
  const sides = +groups.sides;
  const rolls = range(0, amount).map(v => rng.int(1, sides));
  const sum = rolls.reduce((a, v) => a + v);
  const modifier = groups.modifier ? +groups.modifier : 0;
  return sum + modifier;
};

export const validate = (d: string): boolean => !!d.match(regex);

