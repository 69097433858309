import React from "react";

export const resultCard = (props: ResultCardProps) => (
  <div className="card w-100">
    <h4 className="card-header">{props.name}</h4>
    <ul className="list-group list-group-flush">
      <li className="list-group-item">
        <h5 className="card-title">Info</h5>
        <em className="card-text">{[...props.monsterTags].join(", ")}</em>
        <p>
          {props.weapon} ({props.damage}); {props.hp} HP; {props.armor} Armor
        </p>
        <p>
          <em>{[...props.weaponTags].join(", ")}</em>
        </p>
        <p>
          <strong>Instinct: </strong>
          {props.instinct || "N/A"}
        </p>
      </li>
      <li className="list-group-item">
        <h5 className="card-title">Description</h5>
        <p className="card-text">{props.description || "N/A"}</p>
      </li>
      <li className="list-group-item">
        <h5 className="card-title">Useful</h5>
        <p className="card-text">{props.useful || "N/A"}</p>
      </li>
      <li className="list-group-item">
        <h5 className="card-title">Interesting</h5>
        <p className="card-text">{props.interesting || "N/A"}</p>
      </li>
      <li className="list-group-item">
        <h5 className="card-title">Moves</h5>
        <div className="card-text">
          <ul>
            {props.moves.map(m => (
              <li>{m}</li>
            ))}
          </ul>
        </div>
      </li>
      {props.special.length > 0 && (
        <li className="list-group-item">
          <h5 className="card-title">Special qualities</h5>
          <ul>
            {props.special.map(s => (
              <li>{s}</li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  </div>
);

export type ResultCardProps = {
  name: string;
  monsterTags: Set<string>;
  weapon: string;
  damage: string;
  hp: number;
  armor: number;
  weaponTags: Set<string>;
  instinct: string;
  description: string;
  special: string[];
  useful: string;
  interesting: string;
  moves: string[];
};
