
class Rng {
  int = (
    min: number = Number.MIN_SAFE_INTEGER,
    max: number = Number.MAX_SAFE_INTEGER
  ) => Math.floor(Math.random() * (max - min + 1) + min);

  pick = <T>(list: T[]): T => list[this.int(0, list.length-1)];
}

const rng = new Rng();
export default rng;