import React, { FormEvent, Fragment, ChangeEvent } from "react";
import * as diceRoller from "../../Utils/DiceRoller";
import treasureGenerator, {
  TreasureParameters,
  TreasureResult
} from "../../Utils/TreasureGenerator";
import FormCheckbox from "../FormCheckbox/FormCheckbox";

interface State {
  dice: string;
  hoarder: boolean;
  farFromHome: boolean;
  magical: boolean;
  divine: boolean;
  planar: boolean;
  lordOverTheOthers: boolean;
  ancientAndNoteworthy: boolean;
  result: TreasureResult | null;
}

export default class TreasureHelper extends React.Component<{}, State> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      dice: "",
      hoarder: false,
      farFromHome: false,
      magical: false,
      divine: false,
      planar: false,
      lordOverTheOthers: false,
      ancientAndNoteworthy: false,
      result: null
    };
  }

  render = () => (
    <Fragment>
      {this.state.result && (
        <div>
          <h3>Resultat</h3>
          <h4>Loot</h4>
          <ul>
            {this.state.result.items
              .sort(this.itemComparer)
              .map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
          </ul>
          {this.state.result.bonus && this.state.result.bonus.length > 0 && (
            <Fragment>
              <h4>Bonus</h4>
              <ul>
                {this.state.result.bonus.map(bonus => (
                  <li key={bonus}>{bonus}</li>
                ))}
              </ul>
            </Fragment>
          )}
        </div>
      )}
      <div>
        <h3>Input</h3>
        <form onSubmit={this.handleSubmit}>
          <div className="row mb-4">
            <div className="col-7">
              <input
                type="text"
                className="form-control"
                placeholder="Skade terninger"
                value={this.state.dice}
                onChange={this.handleDiceChange}
              />
            </div>
            <div className="col-1" />
            <div className="col-4">
              <FormCheckbox
                label="Langt væk hjemmefra"
                checked={this.state.farFromHome}
                fieldName="farFromHome"
                onChange={this.setAway}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <FormCheckbox
                label="Hamstrer"
                checked={this.state.hoarder}
                fieldName="hoarder"
                onChange={this.setHoarder}
              />
            </div>
            <div className="col">
              <FormCheckbox
                label="Herre over de andre"
                checked={this.state.lordOverTheOthers}
                fieldName="lordOverTheOthers"
                onChange={this.setLord}
              />
            </div>
            <div className="col">
              <FormCheckbox
                label="Ældgammel og bemærkelsesværdig"
                checked={this.state.ancientAndNoteworthy}
                fieldName="ancientAndNoteworthy"
                onChange={this.setAncient}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <FormCheckbox
                label="Magisk"
                checked={this.state.magical}
                fieldName="magical"
                onChange={this.setMagical}
              />
            </div>
            <div className="col">
              <FormCheckbox
                label="Guddommelig"
                checked={this.state.divine}
                fieldName="divine"
                onChange={this.setDivine}
              />
            </div>
            <div className="col">
              <FormCheckbox
                label="Fra en anden dimension"
                checked={this.state.planar}
                fieldName="planar"
                onChange={this.setPlanar}
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-primary btn-block"
            disabled={!diceRoller.validate(this.state.dice)}
          >
            Beregn
          </button>
        </form>
      </div>
    </Fragment>
  );

  private handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!diceRoller.validate(this.state.dice)) return;
    this.setState({
      result: treasureGenerator.generate(this.buildParams())
    });
  };

  private handleDiceChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState({
      dice: event.target.value
    });

  private buildParams = (): TreasureParameters => ({
    diceFn: () => diceRoller.roll(this.state.dice),
    hoarder: this.state.hoarder,
    farFromHome: this.state.farFromHome,
    magical: this.state.magical,
    divine: this.state.divine,
    planar: this.state.planar,
    lordOverTheOthers: this.state.lordOverTheOthers,
    ancientAndNoteworthy: this.state.ancientAndNoteworthy
  });

  private itemComparer = (a: string, b: string): number => {
    const r = /^\d+/;
    const aMatch = a.match(r);
    const bMatch = b.match(r);

    if (!aMatch && bMatch) return 1;
    if (aMatch && !bMatch) return -1;
    if (aMatch && bMatch) return +aMatch[0] - +bMatch[0];
    return a.localeCompare(b);
  };

  private setHoarder = (hoarder: boolean) =>
    this.setState({ hoarder: hoarder });
  private setAway = (farFromHome: boolean) =>
    this.setState({ farFromHome: farFromHome });
  private setMagical = (magical: boolean) =>
    this.setState({ magical: magical });
  private setDivine = (divine: boolean) => this.setState({ divine: divine });
  private setPlanar = (planar: boolean) => this.setState({ planar: planar });
  private setLord = (lordOverTheOthers: boolean) =>
    this.setState({ lordOverTheOthers: lordOverTheOthers });
  private setAncient = (ancientAndNoteworthy: boolean) =>
    this.setState({ ancientAndNoteworthy: ancientAndNoteworthy });
}
