import React from "react";

interface Props {
  value: string;
  fieldName: string;
  title: string;
  button: string;
  hint: string;
  onButtonClick: () => any;
  onValueChange: (newValue: string) => any;
}

export default class FormTextField extends React.Component<Props, {}> {
  render = () => (
    <div className="form-group">
      {this.props.title && (
        <label htmlFor={this.props.fieldName}>{this.props.title}</label>
      )}
      <div className="input-group">
        <input
          type="text"
          value={this.props.value}
          onChange={this.handleChange}
          className="form-control"
          id={this.props.fieldName}
          aria-describedby={`${this.props.fieldName}Help`}
        />
        {this.props.button && (
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={this.props.onButtonClick}
            >
              {this.props.button}
            </button>
          </div>
        )}
      </div>
      {this.props.hint && (
        <small
          id={`${this.props.fieldName}Help`}
          className="form-text text-muted"
        >
          {this.props.hint}
        </small>
      )}
    </div>
  );

  private handleChange = (event: any) =>
    this.props.onValueChange(event.target.value || "");
}
