type MarkdownFile = { name: string; path: string };

const sanitizeFile = (file: string) =>
  file
    .replace(/^\.(\/.*)\.\w+$/, "$1")
    .toLowerCase()
    .trim();

const importAll = (r: any): [MarkdownFile] =>
  r.keys().map((k: string) => ({ name: k, path: r(k) }));

const nameMap = importAll(
  require.context("../Markdown", true, /\.(?:md)$/)
).reduce(
  (map: Map<string, string>, md: MarkdownFile) =>
    map.set(sanitizeFile(md.name), md.path),
  new Map<string, string>()
);

class MarkdownFiles {
  lookup = (path: string): string =>
    nameMap.get(path.toLowerCase().trim()) || "";

  all = Array.from(nameMap.keys()).sort();

  get = (path: string): Promise<string> =>
    new Promise((resolve, reject) => {
      const file = this.lookup(path);
      if (!file) {
        reject(`'${path}' not found.`);
        return;
      }

      fetch(file)
        .then(response => response.text())
        .then(text => resolve(text));
    });
}

const files = new MarkdownFiles();
export default files;
