import React from "react";
import NpcGenerator from "../../Utils/NpcGenerator";
import FormTextField from "../FormTextField/FormTextField";

interface State {
  name: string;
  instinct: string;
  knack: string;
}

export default class NpcHelper extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      name: NpcGenerator.name(),
      instinct: NpcGenerator.instinct(),
      knack: NpcGenerator.knack()
    };
  }

  render = () => (
    <div>
      <p>
        <strong>{this.state.name}</strong> ønsker at{" "}
        <strong>{this.state.instinct}</strong> ved hjælp af{" "}
        <strong>{this.state.knack}</strong>
      </p>
      <div>
        <FormTextField
          title="Navn"
          value={this.state.name}
          button="🎲"
          onButtonClick={() => this.setState({ name: NpcGenerator.name() })}
          onValueChange={v => this.setState({ name: v })}
          hint=""
          fieldName="npcName"
        />
        <FormTextField
          title="Mål"
          value={this.state.instinct}
          button="🎲"
          onButtonClick={() =>
            this.setState({ instinct: NpcGenerator.instinct() })
          }
          onValueChange={v => this.setState({ instinct: v })}
          hint="Hvad personen ønsker at opnå"
          fieldName="npcInstinct"
        />
        <FormTextField
          title="Middel"
          value={this.state.knack}
          button="🎲"
          onButtonClick={() => this.setState({ knack: NpcGenerator.knack() })}
          onValueChange={v => this.setState({ knack: v })}
          hint="Hvordan personen kan opnå sit mål"
          fieldName="npcName"
        />
        <button type="button" onClick={this.reroll} className="btn btn-block btn-lg btn-primary">
          Tilfældig
        </button>
      </div>
    </div>
  );

  private reroll = () =>
    this.setState({
      name: NpcGenerator.name(),
      instinct: NpcGenerator.instinct(),
      knack: NpcGenerator.knack()
    });
}
