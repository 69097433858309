import React, { Fragment } from "react";
import SpinnerGrow from "../SpinnerGrow/SpinnerGrow";

interface State {
  sets: NamedArray[];
  loading: boolean;
  chosen: string;
}

const animalNullValue = "Intet dyr fundet";
export default class DruiseSelector extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      sets: [],
      loading: true,
      chosen: animalNullValue
    };
  }

  render = () => (
    <Fragment>
      {this.state.loading && (
        <div
          className="d-flex justify-content-center align-items-center h-100 w-100 position-fixed"
          style={{ left: 0 }}
        >
          <SpinnerGrow />
        </div>
      )}
      {!this.state.loading && (
        <div>
          <strong>
            Dyr: <em>{this.state.chosen}</em>
          </strong>
          {this.state.sets.slice(1).map((s, i) => (
            <div className="row mt-2" key={`row#${i}`}>
              <button
                className="btn btn-info w-100"
                type="button"
                key={`btn${i}`}
                onClick={() =>
                  this.setState({
                    chosen: this.pickRandom(this.state.sets[i + 1])
                  })
                }
              >
                {s.name}
              </button>
            </div>
          ))}
          <div className="row mt-4">
            <button
              className="btn btn-primary w-100"
              type="button"
              onClick={this.loadData}
            >
              Genindlæs data
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );

  componentDidMount = () => {
    this.loadData();
  };

  private loadData = () => {
    this.setState({ loading: true });
    fetch(
      "https://sheets.googleapis.com/v4/spreadsheets/11ZsDJacD52ERVCSIpeFs-llDHtu-dcxDHxHM3lmc4Tg/values/Sheet1!A1:ZZZ1000?key=AIzaSyAYwKkJpUN2ELoaOfEsSwqq1_Hwj-J_TlE&majorDimension=ROWS"
    )
      .then(response => response.json())
      .then(json => json.values)
      .then(rows => {
        const sets: NamedArray[] = [NamedArray.empty];
        const headers = rows[0];
        for (var h = 1; h < headers.length; h++) {
          sets.push(new NamedArray(headers[h]));
        }
        for (var i = 1; i < rows.length; i++) {
          const row = rows[i];
          const animal = row[0];
          for (var j = 1; j < row.length; j++) {
            const val = row[j];
            if (val === "") continue;
            sets[j].push(animal);
          }
        }
        console.log("Loaded data:", sets);
        this.setState({ loading: false, sets: sets });
      });
  };

  private pickRandom = (a: string[]): string =>
    a.length === 0 ? animalNullValue : a[Math.floor(Math.random() * a.length)];
}

class NamedArray extends Array {
  static readonly empty = new NamedArray("");

  name: string;
  constructor(name: string) {
    super();
    this.name = name;
  }
}
