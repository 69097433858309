import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "jquery";
import "popper.js";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import MonsterHelper from "./Components/MonsterHelper/MonsterHelper";
import NavBar from "./Components/NavBar/NavBar";
import NpcHelper from "./Components/NpcHelper/NpcHelper";
import TreasureHelper from "./Components/TreasureHelper/TreasureHelper";
import Wiki from "./Components/Wiki/Wiki";
import DruiseSelector from "./Components/DruiseSelector/DruiseSelector";

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Route path="/" component={NavBar} />
          <div className="container">
            <Switch>
              <Route path="/npc" exact component={NpcHelper} />
              <Route path="/treasure" exact component={TreasureHelper} />
              <Route path="/monsters" exact component={MonsterHelper} />
              <Route path="/druise" exact component={DruiseSelector} />
              <Route path="/:md(.*)" component={Wiki} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}
