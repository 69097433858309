export interface Die {
  sides: number;
  decrease: Die | undefined;
  increase: Die | undefined;
}

class Dice {
  private mkDie = (sides: number): Die => ({
    sides,
    decrease: undefined,
    increase: undefined
  });

  public readonly d2 = this.mkDie(2);
  public readonly d4 = this.mkDie(4);
  public readonly d6 = this.mkDie(6);
  public readonly d8 = this.mkDie(8);
  public readonly d10 = this.mkDie(10);
  public readonly d12 = this.mkDie(12);
  public readonly d20 = this.mkDie(20);

  constructor() {
    this.d2.increase = this.d4;
    this.d4.decrease = this.d2;
    this.d4.increase = this.d6;
    this.d6.decrease = this.d4;
    this.d6.increase = this.d8;
    this.d8.decrease = this.d6;
    this.d8.increase = this.d10;
    this.d10.decrease = this.d8;
    this.d10.increase = this.d12;
    this.d12.decrease = this.d10;
    this.d12.increase = this.d20;
    this.d20.decrease = this.d12;
  }
}

export default new Dice();
