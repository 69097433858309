import { RouteComponentProps } from "react-router";
import MdRenderers from "../../Utils/MdRenderers";
import React, { Fragment } from "react";
import ReactMarkdown from "react-markdown";
import MarkdownFiles from "../../Utils/MarkdownFiles";
import { Link } from "react-router-dom";
import SpinnerGrow from "../SpinnerGrow/SpinnerGrow";

interface WikiProps extends RouteComponentProps<{}> {}

interface WikiState {
  markdown: string;
  showSpinner: boolean;
}

export default class Wiki extends React.Component<WikiProps, WikiState> {
  private get currentPath() {
    return this.props.location.pathname;
  }

  constructor(props: WikiProps) {
    super(props);
    this.state = { markdown: "", showSpinner: true };
  }

  componentWillMount = () => this.loadData(this.currentPath);

  render = () => (
    <Fragment>
      {this.state.showSpinner && (
        <div
          className="d-flex justify-content-center align-items-center h-100 w-100 position-fixed"
          style={{ left: 0 }}
        >
          <SpinnerGrow />
        </div>
      )}
      {!this.state.showSpinner && this.state.markdown && (
        <Fragment>
          <ReactMarkdown source={this.state.markdown} renderers={MdRenderers} />
        </Fragment>
      )}
      {!this.state.showSpinner && !this.state.markdown && (
        <div>
          <p>'{this.currentPath}' findes ikke. Mente du</p>
          <ul>
            {MarkdownFiles.all.map(p => (
              <li key={p}>
                <Link to={p}>{p}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Fragment>
  );

  componentWillReceiveProps = (props: WikiProps) => {
    const newPath = props.location.pathname;
    if (newPath === this.currentPath) return;
    this.loadData(newPath);
  };

  loadData = (path: string) => {
    this.setState({ showSpinner: true });
    MarkdownFiles.get(path)
      .then(md => {
        this.setState({
          markdown: md,
          showSpinner: false
        });
      })
      .catch(_ => {
        this.setState({
          markdown: "",
          showSpinner: false
        });
      });
  };
}
