import React from "react";
import { ResultCardProps, resultCard } from "./ResultCard";
import Dice from "../../Utils/Dice";
import MonsterExport from "./MonsterExport";
import { MoveDescription } from "./MoveDescription";

export interface MonsterHelperState {
  name: string;
  desc: string;
  instinct: string;
  weapon: string;
  moves: MoveDescription[];

  groupSelection: string;
  sizeSelection: string;
  defenseSelection: string;

  selections: { [key: string]: boolean };

  interesting: string;
  useful: string;
}

const keyDesc = (key: string, desc: string) => ({ key, desc });

const groupOptions = {
  horde: keyDesc("groupHorde", "In large groups"),
  group: keyDesc("groupGroup", "In small groups, about 2—5"),
  solo: keyDesc("groupSolitary", "All by its lonesome")
};

const sizeOptions = {
  tiny: keyDesc("sizeTiny", "Smaller than a house cat"),
  small: keyDesc("sizeSmall", "Halfling-esque"),
  medium: keyDesc("sizeMedium", "About human size"),
  large: keyDesc("sizeLarge", "As big as a cart"),
  huge: keyDesc("sizeHuge", "Much larger than a cart")
};

const defenseOptions = {
  cloth: keyDesc("defenseCloth", "Cloth or flesh"),
  leather: keyDesc("defenseLeather", "Leathers or thick hide"),
  mail: keyDesc("defenseMail", "Mail or scales"),
  plate: keyDesc("defensePlate", "Plate or bone"),
  magical: keyDesc("defenseMagical", "Permanent magical protection")
};

const knownForOptions = {
  strength: keyDesc("knownStrength", "Unrelenting strength"),
  offense: keyDesc("knownOffense", "Skill in offense"),
  defense: keyDesc("knownDefense", "Skill in defense"),
  pierce: keyDesc("knownPierce", "Deft strikes"),
  endurance: keyDesc("knownEndurance", "Uncanny endurance"),
  deceit: keyDesc("knownDeceit", "Deceit and trickery"),
  adaptation: keyDesc(
    "knownAdaptation",
    "A useful adaptation like being amphibious or having wings"
  ),
  divinePower: keyDesc("knownDivinePower", "The favor of the gods (+2 dmg)"),
  divineEndurance: keyDesc(
    "knownDivineEndurance",
    "The favor of the gods (+2 HP)"
  ),
  magic: keyDesc("knownMagic", "Spells and magic")
};

const attackOptions = {
  vicious: keyDesc("attackVicious", "Its armaments are vicious and obvious"),
  reach: keyDesc("attackReach", "It lets the monster keep others at bay"),
  weak: keyDesc("attackWeak", "Its armaments are small and weak"),
  pierce: keyDesc("attackPierce", "Its armaments can slice or pierce metal"),
  piercePlus: keyDesc("attackPiercePlus", "It can tear metal apart"),
  armor: keyDesc(
    "attackArmor",
    "Armor doesn’t help with the damage it deals (due to magic, size, etc.)"
  ),
  rangeNear: keyDesc(
    "attackRangeNear",
    "It usually attacks at range with arrows, spells, or other projectiles (near)"
  ),
  rangeFar: keyDesc(
    "attackRangeFar",
    "It usually attacks at range with arrows, spells, or other projectiles (far)"
  )
};

const descOptions = {
  devious: keyDesc(
    "descDevious",
    "It isn’t dangerous because of the wounds it inflicts, but for other reasons"
  ),
  organized: keyDesc(
    "descOrganized",
    "It organizes into larger groups that it can call on for support"
  ),
  intelligent: keyDesc(
    "descIntelligent",
    "It’s as smart as a human or thereabouts"
  ),
  cautious: keyDesc(
    "descCautious",
    "It actively defends itself with a shield or similar"
  ),
  hoarder: keyDesc(
    "descHoarder",
    "It collects trinkets that humans would consider valuable (gold, gems, secrets)"
  ),
  planar: keyDesc("descPlanar", "It’s from beyond this world"),
  complex: keyDesc(
    "descComplex",
    "It’s kept alive by something beyond simple biology"
  ),
  construct: keyDesc("descConstruct", "It was made by someone"),
  terrifying: keyDesc(
    "descTerrifying",
    "Its appearance is disturbing, terrible, or horrible"
  ),
  amorphous: keyDesc(
    "descAmorphous",
    "It doesn’t have organs or discernible anatomy"
  ),
  ancient: keyDesc(
    "descAncient",
    "It (or its species) is ancient—older than man, elves, and dwarves"
  ),
  pacifist: keyDesc("descPacifist", "It abhors violence")
};

export default class MonsterHelper extends React.Component<
  {},
  MonsterHelperState
> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      name: "",
      desc: "",
      instinct: "",
      weapon: "",
      moves: [],

      groupSelection: groupOptions.solo.key,
      sizeSelection: sizeOptions.medium.key,
      defenseSelection: defenseOptions.cloth.key,

      selections: {},

      interesting: "",
      useful: ""
    };
  }

  render = () => (
    <div className="row">
      <div className="col">
        <form>
          {this.inputRow({
            id: "name",
            multiline: false,
            label: "Name",
            value: this.state.name,
            onChange: name => this.setState({ name })
          })}
          {this.inputRow({
            id: "one",
            multiline: true,
            label: "Description",
            value: this.state.desc,
            onChange: desc => this.setState({ desc })
          })}
          {this.inputRow({
            id: "two",
            multiline: true,
            label: "What does it want that causes problems for others?",
            value: this.state.instinct,
            onChange: instinct => this.setState({ instinct })
          })}
          {this.radioGroup({
            title: "How does it usually hunt or fight?",
            name: "groupSelection",
            opts: groupOptions,
            onchange: groupSelection => this.setState({ groupSelection })
          })}
          {this.radioGroup({
            title: "How big is it?",
            name: "sizeSelection",
            opts: sizeOptions,
            onchange: sizeSelection => this.setState({ sizeSelection })
          })}
          {this.radioGroup({
            title: "What is it's most important defense?",
            name: "defenseSelection",
            opts: defenseOptions,
            onchange: defenseSelection => this.setState({ defenseSelection })
          })}
          {this.inputRow({
            id: "weapon",
            multiline: false,
            label:
              "What is its most common form of attack? (A type of weapon, claws or talons, a specific spell)",
            value: this.state.weapon,
            onChange: weapon => this.setState({ weapon })
          })}
          {this.checkGroup({
            title: "What is it known for?",
            opts: knownForOptions,
            onChange: opt =>
              this.setState(p => ({
                selections: {
                  ...p.selections,
                  [opt]: !p.selections[opt]
                }
              }))
          })}
          {this.checkGroup({
            title: "Describe its attacks",
            opts: attackOptions,
            onChange: opt =>
              this.setState(p => ({
                selections: {
                  ...p.selections,
                  [opt]: !p.selections[opt]
                }
              }))
          })}
          {this.checkGroup({
            title: "Which of these describe it?",
            opts: descOptions,
            onChange: opt =>
              this.setState(p => ({
                selections: {
                  ...p.selections,
                  [opt]: !p.selections[opt]
                }
              }))
          })}
          {this.inputRow({
            id: "interesting",
            label:
              "What is something interesting for the PCs to learn about this monster?",
            value: this.state.interesting,
            multiline: true,
            onChange: interesting => this.setState({ interesting })
          })}
          {this.inputRow({
            id: "useful",
            label:
              "What is something useful for the PCs to learn about this monster?",
            value: this.state.useful,
            multiline: true,
            onChange: useful => this.setState({ useful })
          })}
          {this.moveList()}
        </form>
        <div className="mt-4">
          <MonsterExport mhs={this.state} rcp={this.getResultProps()} />
        </div>
        <div style={{ height: "300px" }} />
      </div>
      <div className="col">
        <div className="sticky-top">
          <div className="row">{resultCard(this.getResultProps())}</div>
        </div>
      </div>
    </div>
  );

  onComponentDidUpdate = () => {
    window.location.pathname = this.state.name
  }

  private inputRow = (props: {
    id: string;
    label: string;
    value: string;
    multiline: boolean;
    onChange: (newValue: string) => any;
  }) => (
    <div className="form-group">
      <label htmlFor={props.id}>
        <h6>{props.label}</h6>
      </label>
      <div>
        {(props.multiline && (
          <textarea
            className="form-control"
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          />
        )) || (
          <input
            type="text"
            className="form-control"
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          />
        )}
      </div>
    </div>
  );

  private radioGroup = (props: {
    title: string;
    name: string;
    opts: any;
    onchange: (newValue: string) => any;
  }) => (
    <div className="mb-2">
      <h6 className="mt-2">{props.title}</h6>
      {Object.keys(props.opts).map(k => (
        <div className="custom-control custom-radio">
          <input
            type="radio"
            className="custom-control-input"
            name={props.name}
            id={props.opts[k].key}
            onChange={() => props.onchange(props.opts[k].key)}
          />
          <label htmlFor={props.opts[k].key} className="custom-control-label">
            {props.opts[k].desc}
          </label>
        </div>
      ))}
    </div>
  );

  private checkGroup = (props: {
    title: string;
    opts: any;
    onChange: (selection: string) => any;
  }) => (
    <div className="mb-2">
      <h6 className="mt-2">{props.title}</h6>
      {Object.keys(props.opts).map(k => (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={props.opts[k].key}
            onChange={() => props.onChange(props.opts[k].key)}
          />
          <label htmlFor={props.opts[k].key} className="custom-control-label">
            {props.opts[k].desc}
          </label>
        </div>
      ))}
    </div>
  );

  private getResultProps = () => {
    const result: ResultCardProps = {
      name: this.state.name || "Unnamed",
      monsterTags: new Set(),
      weapon: this.state.weapon,
      damage: "",
      hp: 0,
      armor: 0,
      weaponTags: new Set(),
      instinct: this.state.instinct,
      description: this.state.desc,
      special: [],
      useful: this.state.useful,
      interesting: this.state.interesting,
      moves: this.state.moves.map(mv => mv.desc)
    };
    var dmgDie = Dice.d6;
    var dmgMod = 0;
    var advantage = 0;
    var armorPiercing = 0;

    switch (this.state.groupSelection) {
      case groupOptions.horde.key:
        result.monsterTags.add("Horde");
        dmgDie = Dice.d6;
        result.hp = 3;
        break;
      case groupOptions.group.key:
        result.monsterTags.add("Group");
        dmgDie = Dice.d8;
        result.hp = 6;
        break;
      case groupOptions.solo.key:
        result.monsterTags.add("Solitary");
        dmgDie = Dice.d10;
        result.hp = 12;
        break;
    }

    switch (this.state.sizeSelection) {
      case sizeOptions.tiny.key:
        result.monsterTags.add("Tiny");
        result.weaponTags.add("Hand");
        dmgMod += -2;
        break;
      case sizeOptions.small.key:
        result.monsterTags.add("Small");
        result.weaponTags.add("Close");
        break;
      case sizeOptions.medium.key:
        result.weaponTags.add("Close");
        break;
      case sizeOptions.large.key:
        result.monsterTags.add("Large");
        result.weaponTags.add("Close");
        result.weaponTags.add("Reach");
        result.hp += 4;
        dmgMod += 1;
        break;
      case sizeOptions.huge.key:
        result.monsterTags.add("Huge");
        result.weaponTags.add("Reach");
        result.hp += 4;
        dmgMod += 3;
        break;
    }

    switch (this.state.defenseSelection) {
      case defenseOptions.cloth.key:
        result.armor = 0;
        break;
      case defenseOptions.leather.key:
        result.armor = 1;
        break;
      case defenseOptions.mail.key:
        result.armor = 2;
        break;
      case defenseOptions.plate.key:
        result.armor = 3;
        break;
      case defenseOptions.magical.key:
        result.armor = 4;
        result.monsterTags.add("Magical");
        break;
    }

    if (this.state.selections[knownForOptions.strength.key]) {
      dmgMod += 2;
      result.weaponTags.add("Forceful");
    }
    if (this.state.selections[knownForOptions.offense.key]) {
      advantage += 1;
    }
    if (this.state.selections[knownForOptions.defense.key]) {
      result.armor += 1;
    }
    if (this.state.selections[knownForOptions.pierce.key]) {
      armorPiercing += 1;
    }
    if (this.state.selections[knownForOptions.endurance.key]) {
      result.hp += 4;
    }
    if (this.state.selections[knownForOptions.deceit.key]) {
      result.monsterTags.add("Stealthy");
      result.special.push(
        "Write a move about dirty tricks (Deceit & Trickery)"
      );
    }
    if (this.state.selections[knownForOptions.adaptation.key]) {
      result.special.push("Add a special quality for the adaptation");
    }
    if (this.state.selections[knownForOptions.divinePower.key]) {
      dmgMod += 2;
    }
    if (this.state.selections[knownForOptions.divineEndurance.key]) {
      result.hp += 2;
    }
    if (this.state.selections[knownForOptions.magic.key]) {
      result.monsterTags.add("Magical");
      result.special.push("Write a move about its spells");
    }

    if (this.state.selections[attackOptions.vicious.key]) {
      dmgMod += 2;
    }
    if (this.state.selections[attackOptions.reach.key]) {
      result.weaponTags.add("Reach");
    }
    if (this.state.selections[attackOptions.weak.key]) {
      dmgDie = dmgDie.decrease || Dice.d2;
    }
    if (this.state.selections[attackOptions.pierce.key]) {
      result.weaponTags.add("Messy");
      armorPiercing += 1;

      if (this.state.selections[attackOptions.piercePlus.key]) {
        armorPiercing += 2;
      }
    }
    if (this.state.selections[attackOptions.armor.key]) {
      result.weaponTags.add("Ignores armor");
    }
    if (this.state.selections[attackOptions.rangeNear.key]) {
      result.weaponTags.add("Near");
    }
    if (this.state.selections[attackOptions.rangeFar.key]) {
      result.weaponTags.add("Far");
    }

    if (this.state.selections[descOptions.devious.key]) {
      dmgDie = dmgDie.decrease || Dice.d2;
      result.monsterTags.add("Devious");
      result.special.push("Write a move about why it's dangerous (devious)");
    }
    if (this.state.selections[descOptions.organized.key]) {
      result.monsterTags.add("Organized");
      result.special.push(
        "Write a move about calling on others for help (organized)"
      );
    }
    if (this.state.selections[descOptions.intelligent.key]) {
      result.monsterTags.add("Intelligent");
    }
    if (this.state.selections[descOptions.cautious.key]) {
      result.monsterTags.add("Cautious");
      result.armor += 1;
    }
    if (this.state.selections[descOptions.hoarder.key]) {
      result.monsterTags.add("Hoarder");
    }
    if (this.state.selections[descOptions.planar.key]) {
      result.monsterTags.add("Planar");
      result.special.push(
        "Write a move about using its otherworldly knowledge and power (planar)"
      );
    }
    if (this.state.selections[descOptions.complex.key]) {
      result.hp += 4;
    }
    if (this.state.selections[descOptions.construct.key]) {
      result.monsterTags.add("Construct");
      result.special.push(
        "Give it a special quality or two about its construction or purpose (construct)"
      );
    }
    if (this.state.selections[descOptions.terrifying.key]) {
      result.monsterTags.add("Terrifying");
      result.special.push(
        "Write a special quality about why it is so horrendous (terrifying)"
      );
    }
    if (this.state.selections[descOptions.amorphous.key]) {
      result.monsterTags.add("Amorphous");
      result.armor += 1;
      result.hp += 3;
    }
    if (this.state.selections[descOptions.ancient.key]) {
      result.monsterTags.add("Ancient");
      dmgDie = dmgDie.increase || Dice.d20;
    }
    if (this.state.selections[descOptions.pacifist.key]) {
      advantage -= 1;
    }

    const dmgDieString =
      advantage < 0
        ? `w[2d${dmgDie.sides}]`
        : advantage > 0
        ? `b[2d${dmgDie.sides}]`
        : `1d${dmgDie.sides}`;
    const dmgModString =
      dmgMod < 0 ? ` - ${Math.abs(dmgMod)}` : dmgMod > 0 ? ` + ${dmgMod}` : "";
    const armorPiercingString =
      armorPiercing > 0 ? `, + ${armorPiercing} AP` : "";
    result.damage = `${dmgDieString}${dmgModString}${armorPiercingString}`;

    return result;
  };

  private moveInput = (md: MoveDescription, id: number) => (
    <div className="input-group mb-3">
      <input
        id={`move-${id}`}
        type="text"
        className="form-control"
        placeholder={md.placeholder}
        aria-label={md.type}
        value={md.desc}
        onChange={e => {
          md.desc = e.target.value;
          this.setState({ moves: this.state.moves });
        }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => {
            this.state.moves.splice(id, 1);
            this.setState({ moves: this.state.moves });
          }}
        >
          🗑
        </button>
      </div>
    </div>
  );

  private moveList = () => (
    <div>
      <h5>Moves:</h5>
      {this.state.moves.map((m, i) => this.moveInput(m, i))}
      <button
        type="button"
        className="btn btn-outline btn-primary"
        onClick={() =>
          this.setState({ moves: [...this.state.moves, this.mkMove()] })
        }
      >
        Add move
      </button>
    </div>
  );

  private mkMove = (
    placeholder: string = "Describe your move",
    type: "attack" | "main" = "attack",
    desc: string = ""
  ): MoveDescription => ({
    placeholder,
    type,
    desc
  });
}
