import React, { ChangeEvent } from "react";

interface Props {
  checked: boolean;
  label: string;
  fieldName: string;
  onChange: (newValue: boolean) => any;
}

export default class FormCheckbox extends React.Component<Props, {}> {
  render = () => (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={this.props.checked}
        id={this.props.fieldName}
        onChange={this.handleChange}
      />
      <label className="form-check-label" htmlFor={this.props.fieldName}>
        {this.props.label}
      </label>
    </div>
  );

  private handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.props.onChange(event.target.checked);
}
